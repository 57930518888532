import axios from 'axios';


const service = axios.create({
    baseURL: 'https://information.mabarcode.cn/api/',
    timeout: 10000
})

service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data;
        return res;
    },
    error => {
        console.log(error)
    }
)

export default service;