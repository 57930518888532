<template>
  <div id="app">
    <div class="search">
      <input
        class="inputValue"
        v-model="searchValue"
        type="text"
        placeholder="搜索关键词"
        @keyup.enter="submitSearch()"
      />
      <div class="searchBtn" @click="submitSearch()">
        <input
          type="submit"
          style="
            width: 100%;
            height: 100%;
            background: rgb(78, 110, 242);
            border: none;
          "
          value="搜索"
        />
      </div>
    </div>
    <div class="title" v-if="result1 || result2 || search1 || search2">
      搜索内容
    </div>
    <div class="content" v-if="search1">
      <span>
        <span style="font-weight: bold">搜索关键词1:</span>
        <div
          v-if="searchValue == search1"
          style="margin-top: 8px; color: #ff0000; font-weight: 600"
        >
          {{ search1 }}
        </div>
        <div v-else style="margin-top: 8px">{{ search1 }}</div>
      </span>
    </div>
    <div class="content" v-if="search2">
      <span>
        <span style="font-weight: bold">搜索关键词2:</span>
        <div
          v-if="searchValue == search2"
          style="margin-top: 8px; color: #ff0000; font-weight: 600"
        >
          {{ search2 }}
        </div>
        <div v-else style="margin-top: 8px">{{ search2 }}</div>
      </span>
    </div>
    <div class="content" v-if="result1">
      <span style="font-weight: bold">查询结果1:</span>
      <div style="margin-top: 8px">{{ result1 }}</div>
    </div>
    <div class="content" v-if="result2">
      <span style="font-weight: bold">查询结果2:</span>
      <div style="margin-top: 8px">{{ result2 }}</div>
    </div>
    <div class="mask" v-if="msg">
      <div class="maskInfo">
        <span>{{ msg }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { search } from "./api";
export default {
  name: "App",
  data() {
    return {
      searchValue: "",
      result1: "",
      result2: "",
      search1: "",
      search2: "",
      active: false,
      msg: "",
    };
  },
  created() {
    document.title = "信息查询平台";
  },
  methods: {
    repeat() {
      this.result1 = "";
      this.result2 = "";
      this.search1 = "";
      this.search2 = "";
    },
    async submitSearch() {
      const res = await search({
        search: this.searchValue,
      });
      if (res.code == 0) {
        if (!res.data) {
          console.log(window);
          if (window.innerWidth >= 560) {
            this.$alert("未查询到结果", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                console.log(action);
              },
            });
          } else {
            this.msg = "未查询到结果";
            setTimeout(() => {
              this.msg = "";
            }, 2000);
          }

          return false;
        }
        this.result1 = res.data.result1;
        this.result2 = res.data.result2;
        this.search1 = res.data.search1;
        this.search2 = res.data.search2;
      } else {
        if (window.innerWidth >= 560) {
          this.$alert(res.msg, "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              console.log(action);
            },
          });
        } else {
          this.msg = res.msg;
          setTimeout(() => {
            this.msg = "";
          }, 2000);
        }
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 120px;
}

.search {
  width: 703px;
  height: 60px;
  margin: 0 auto;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  /* border: 2px solid rgb(167, 170, 181); */
  overflow: hidden;
}
.inputValue {
  width: 595px;
  border: none;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 18px;
  border: 2px solid rgb(167, 170, 181);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: none;
}
input:focus {
  outline: none;
  border-color: rgb(78, 110, 242);
  box-shadow: none;
}
.searchBtn {
  width: 108px;
  height: 60px;
}
.searchBtn > input {
  color: #fff;
  font-size: 16px;
}
.title {
  width: 703px;
  border-bottom: 2px solid rgb(167, 170, 181);
  padding-bottom: 20px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: bold;
  margin: 40px auto 0;
}
.content {
  font-size: 18px;
  width: 703px;
  margin: 16px auto 0;
}
.mask {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
}
.maskInfo {
  height: 30px;
  width: 120px;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
@media (max-width: 560px) {
  .search {
    width: 375px;
    height: 40px;
    border: 1px solid rgb(167, 170, 181);
  }
  .inputValue {
    width: 335px;
    font-size: 16px;
  }
  .searchBtn {
    width: 90px;
    height: 40px;
  }
  .title {
    width: 375px;
    font-size: 24px !important;
  }
  .content {
    width: 375px;
  }

  .mobile-message-box {
    /* 针对移动端的样式调整 */
    font-size: 16px; /* 可以根据需要调整字体大小 */
    padding: 10px; /* 可以根据需要调整内边距 */
    /* 其他样式 */
  }
  #app {
    margin-top: 30px;
  }
}
</style>
